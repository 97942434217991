import React, { useEffect, useContext, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from './../components/panel/panel.jsx';
import { AppSettings } from './../config/app-settings.js';

function Dashboard() {
  const context = useContext(AppSettings);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    return () => {
      context.handleSetAppSidebarNone(true);
    };
	}, []);

  return (
    <div>
      <Panel>
        <PanelBody>
          <p style={{
            padding: "20px",
            textAlign: "center",
          }}>
          <i class="fa fa-cloud-upload fa-5x"></i>
          </p>
        </PanelBody>
      </Panel>

    </div>
  );
}

export default Dashboard;
